var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveOrder.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"headline"},[_vm._v(_vm._s(_vm.heading))])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"address",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.addressItems,"error":!valid,"error-messages":errors,"item-text":"description","item-value":"uuid","prepend-icon":"mdi-map-marker-radius","loading":_vm.loadingAddresses,"disabled":_vm.loading,"label":_vm.$t('address'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":""},on:{"click:clear":_vm.clearAddress},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-icon',{attrs:{"color":"primary darken-2"},domProps:{"textContent":_vm._s('mdi-plus')},on:{"click":_vm.createAddress}})],1)]},proxy:true}],null,true),model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"sender_name",attrs:{"rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-courier","label":_vm.$t('name_sender'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.sender_name),callback:function ($$v) {_vm.sender_name=$$v},expression:"sender_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"type":"tel","error-messages":errors,"error":!valid,"disabled":_vm.loading,"prepend-icon":"mdi-phone","label":_vm.$t('phone_sender'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.phoneRaw),callback:function ($$v) {_vm.phoneRaw=$$v},expression:"phoneRaw"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{ref:"on_date",attrs:{"name":"on_date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"error":!valid,"disabled":_vm.loading,"label":_vm.$t('on_date'),"prepend-icon":"mdi-calendar","color":"primary","readonly":"","outlined":"","rounded":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.on_date = null}},model:{value:(_vm.on_date),callback:function ($$v) {_vm.on_date=$$v},expression:"on_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.onDateMenu),callback:function ($$v) {_vm.onDateMenu=$$v},expression:"onDateMenu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":_vm.$moment().format('YYYY-MM-DD'),"max":_vm.$moment().add(100, 'y').format('YYYY-MM-DD'),"locale":_vm.lang},on:{"change":function($event){_vm.onDateMenu = false}},model:{value:(_vm.on_date),callback:function ($$v) {_vm.on_date=$$v},expression:"on_date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{ref:"on_time",attrs:{"name":"on_time","rules":"min:4|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"disabled":_vm.loading,"label":_vm.$t('on_time'),"prepend-icon":"mdi-clock-outline","color":"primary","readonly":"","outlined":"","rounded":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.on_time = null}},model:{value:(_vm.on_time),callback:function ($$v) {_vm.on_time=$$v},expression:"on_time"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.onTimeMenu),callback:function ($$v) {_vm.onTimeMenu=$$v},expression:"onTimeMenu"}},[_c('v-time-picker',{ref:"picker",attrs:{"format":"24hr","min":(_vm.$moment().format('YYYY-MM-DD') == _vm.on_date ) ? _vm.$moment().tz(_vm.$auth.user().timezone).format('HH:mm') : '',"locale":_vm.lang},on:{"change":function($event){_vm.onTimeMenu = false}},model:{value:(_vm.on_time),callback:function ($$v) {_vm.on_time=$$v},expression:"on_time"}})],1)],1),_c('v-col',{staticClass:"py-0 d-flex justify-start",attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{staticClass:"ma-0",attrs:{"disabled":_vm.loading,"label":_vm.$t('cash'),"color":"primary"},model:{value:(_vm.cash),callback:function ($$v) {_vm.cash=$$v},expression:"cash"}})],1),_c('v-col',{staticClass:"py-0 d-flex justify-start justify-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{staticClass:"ma-0",attrs:{"disabled":_vm.loading,"label":_vm.$t('immediate'),"color":"primary"},model:{value:(_vm.immediate),callback:function ($$v) {_vm.immediate=$$v},expression:"immediate"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"description ",attrs:{"rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"label":_vm.$t('order_description'),"rows":"5","color":"primary","auto-grow":"","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.description ),callback:function ($$v) {_vm.description =$$v},expression:"description "}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }