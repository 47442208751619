<template>
    <v-container fluid>
        <v-form @submit.prevent="saveOrder">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{heading}}</v-subheader>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="address" rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="address" :items="addressItems"
                                                        :error="!valid" :error-messages="errors"
                                                        item-text="description" item-value="uuid" prepend-icon="mdi-map-marker-radius"
                                                        :loading="loadingAddresses" :disabled="loading"
                                                        :label="$t('address')" @click:clear="clearAddress"
                                                        outlined
                                                        rounded
                                                        dense
                                                        color="primary" return-object clearable >
                                            <template v-slot:append-outer>
                                                <v-slide-x-reverse-transition
                                                    mode="out-in"
                                                >
                                                    <v-icon
                                                        @click="createAddress"

                                                        color="primary darken-2"

                                                        v-text="'mdi-plus'"
                                                    ></v-icon>
                                                </v-slide-x-reverse-transition>
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="sender_name" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="sender_name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-courier"
                                                  :label="$t('name_sender')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="phone" rules="required|phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="phoneMask"
                                                  :error-messages="errors" :error="!valid"
                                                  :disabled="loading" prepend-icon="mdi-phone"
                                                  :label="$t('phone_sender')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="onDateMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="on_date" name="on_date" rules="required|date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="on_date" :error-messages="errors"
                                                          :error="!valid"
                                                          :disabled="loading" :label="$t('on_date')"
                                                          prepend-icon="mdi-calendar" color="primary" readonly v-on="on"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable @click:clear="on_date = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="on_date"
                                                   :min="$moment().format('YYYY-MM-DD')"
                                                   :max="$moment().add(100, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="onDateMenu = false"></v-date-picker>
                                </v-menu>


                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="onTimeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="on_time" name="on_time" rules="min:4|max:5" v-slot="{ errors, valid }">
                                            <v-text-field v-model="on_time" :error-messages="errors"
                                                          :disabled="loading" :label="$t('on_time')"
                                                          prepend-icon="mdi-clock-outline" color="primary" readonly v-on="on"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable @click:clear="on_time = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-time-picker ref="picker" v-model="on_time"
                                                   format="24hr"
                                                   :min="($moment().format('YYYY-MM-DD') == on_date ) ? $moment().tz($auth.user().timezone).format('HH:mm') : ''"
                                                   :locale="lang" @change="onTimeMenu = false"></v-time-picker>
                                </v-menu>


                            </v-col>

                            <!--
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="contract_code" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="contract_code" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-newspaper-variant-outline"
                                                  :label="$t('contract_code')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="company_name" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="company_name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('company_name')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            -->

                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="cash" :disabled="loading"
                                          :label="$t('cash')" class="ma-0"
                                          color="primary"></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="immediate" :disabled="loading"
                                          :label="$t('immediate')" class="ma-0"
                                          color="primary"></v-switch>
                            </v-col>

                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="description " rules="min:3|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="description " type="text"
                                                :error="!valid" :error-messages="errors"
                                                :disabled="loading" :label="$t('order_description')"
                                                rows="5" color="primary" auto-grow
                                                outlined
                                                rounded
                                                dense
                                                clearable>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import {mapGetters} from "vuex"



    export default {
        name: 'OrderForm',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,

        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                loading: false,
                create: false,
                language: null,
                phoneRaw: null,
                cash: null,
                immediate : null,
                description : null,
                sender_name : null,
                company_name : null,
                contract_code : null,
                on_date : null,
                onDateMenu: false,
                on_time : null,
                onTimeMenu: false,
                loadingAddresses: false,
                address: null,
                addressItems: [],

                format: {
                    type: String,
                    default: 'ampm'
                },


                exist_translations: {},
                all_translations: true,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            languages() {
                return this.listLanguages
            },
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getAddress()
            await this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === 'order.create') {
                    this.create = true
                    this.heading = this.$t('order_creation')
                } else {
                    this.heading = this.$t('order_editing')
                    if (this.$route.params.id) {
                        this.getOrder(this.$route.params.id)
                    }
                }
            },
            clearAddress() {
                this.address = null
            },
            async setLanguage(val) {
                this.language = this.languages[val]
                await this.checkCreate()
            },
            async getAddress() {
                this.loadingAddresses = true
                let params = {};
                params.filter = 'search';
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("account/address", {
                        params: params,
                    })
                    .then(res => {
                        this.addressItems = res.body.data
                    })
                    .catch(err => {
                        this.addressItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_addresses'))
                    })
                    .finally(end => {
                        this.loadingAddresses = false
                    })
            },
            async getOrder(id) {
                var _this = this
                this.progress = 0
                this.loading = true
                this.id = id
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`account/order/${id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.phoneRaw = res.body.data.phone
                        this.cash = res.body.data.is_cash
                        this.immediate = res.body.data.important
                        this.sender_name = res.body.data.sender_name
                        this.company_name = res.body.data.company_name
                        this.contract_code = res.body.data.contract_code
                        this.on_date = res.body.data.on_date
                        this.on_time = res.body.data.on_time
                        this.description = res.body.data.description
                        this.address = res.body.data.address.uuid


                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_order'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveOrder() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.phone) {
                    formData.append('phone', this.phone)
                }
                if (this.cash) {
                    formData.append('cash', 1)
                }
                if (this.immediate) {
                    formData.append('immediate', 1)
                }
                if (this.company_name) {
                    formData.append('company_name', this.company_name)
                }
                if (this.contract_code) {
                    formData.append('contract_code', this.contract_code)
                }
                if (this.on_date) {
                    formData.append('on_date', this.on_date)
                }
                if (this.on_time) {
                    formData.append('on_time', this.on_time)
                }
                if (this.description) {
                    formData.append('description', this.description)
                }
                if (this.sender_name) {
                    formData.append('sender_name', this.sender_name)
                }



                if (this.address) {
                    if (this.address.uuid) {
                        formData.append('address', this.address.uuid)
                    } else {
                        formData.append('address', this.address)
                    }
                }

                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`account/order/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('order_has_been_updated'))
                            this.$router.push({
                                name: 'orders'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('order_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('account/order', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('order_has_been_added'))
                            this.$router.push({
                                name: 'orders'
                            })
                            /*
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.$router.push({
                                    name: 'order.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'order'
                                })
                            }
                             */
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('order_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            createAddress() {
                this.$router.push({
                    name: 'address.create',
                })
            },
        }
    }
</script>
